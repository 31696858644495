import React from 'react';

const OurMission = () => {
  return (
    <section className="my-10 font-rubik">
      <div className="flex flex-col justify-center items-center">
        <div
          className="bg-cover bg-center h-72 flex items-center relative"
          style={{
            backgroundImage: `url('assets/images/generalbg.png') `,
          }}
        >
          <h1 className="text-BrandCerulean font-bold underline text-[36px] md:text-[60px]">
            Our Mission:
          </h1>
        </div>
        <div className="text-center font-[600] opacity-60 text-[18px] md:text-[28px] pt-0 md:max-w-[60rem] leading-10">
          <p className="">
            At UniCred, we believe that accessing credit should be a seamless,
            global experience—whether you're relocating to a new country or
            simply seeking to expand your financial opportunities. Our mission
            is to bridge the gap between national borders and financial systems,
            providing you with the tools to build, manage, and leverage your
            credit score no matter where life takes you.
          </p>
          <p className="">
            As a trusted partner for individuals, especially Nigerians migrating
            to Canada and other parts of the world, we strive to make
            international credit access simple, secure, and attainable. We
            understand the complexities of starting fresh in a new country and
            are here to help you build a strong financial foundation from day
            one.
          </p>
        </div>
      </div>
    </section>
  );
};

export default OurMission;
