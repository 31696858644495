import React from 'react';
import BaseDirectories from '../../base directories/BaseDiectories';
import Button from '../ui/Button';

const CreditJourney = () => {
  return (
    <section className="font-rubik">
      <div
        className="bg-no-repeat bg-center h-44"
        style={{
          backgroundImage: `url('assets/images/generalbg.png') `,
        }}
      >
        <div className="font-bold text-5xl text-center text-BrandCerulean flex flex-col underline">
          <span className="mt-8">Unlock Your Credit Journey:</span>
          <span>From Home to Here with Hybrid Credit</span>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center gap-6">
        <div className="m-4">
          <img
            className="w-screen"
            src={`${BaseDirectories.IMAGES_DIR}/HomeSection/uycj.png`}
            alt="hero"
          />
        </div>
        <div>
          <Button
            content="Sign Up Now"
            route="/signup"
            className="px-8 flex py-2 text-lg rounded-md text-white shadow-lg bg-BrandCerulean uppercase font-bold"
          />
        </div>
      </div>
    </section>
  );
};

export default CreditJourney;
