import React from 'react';
import { Helmet } from 'react-helmet-async';
import MainLayout from '../../layouts/MainLayout';
import AboutHero from '../../components/sections/AboutHero';
import OurMission from '../../components/sections/OurMission';
import WhoWeAre from '../../components/sections/WhoWeAre';

const About = () => {
  return (
    <>
      <Helmet>
        <title>UNICRED</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="what is the content" />
        <meta name="keywords" content="Add keywords here" />
      </Helmet>
      <MainLayout>
        <AboutHero />
        <section className="md:mx-48 mx-2">
          <OurMission />
          <WhoWeAre />
        </section>
      </MainLayout>
    </>
  );
};

export default About;
