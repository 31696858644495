import React from 'react'

const WhoWeAre = () => {
  return (
    <section className="my-10 font-rubik">
      <div className="flex flex-col justify-center items-center">
        <div
          className="bg-cover bg-center h-72 flex items-center relative"
          style={{
            backgroundImage: `url('assets/images/generalbg.png') `,
          }}
        >
          <h1 className="text-BrandCerulean font-bold underline text-[36px] md:text-[60px]">
          Who We Are:
          </h1>
        </div>
        <div className="text-center font-[600] opacity-60 text-[18px] md:text-[28px] pt-0 max-w-[60rem] leading-10">
          <p className="">
          UniCred is a forward-thinking global financial service provider that specializes in helping migrants establish and manage their credit profiles across borders. Our services cater to individuals moving from Nigeria to destinations like Canada, the US, the UK, and beyond. We collaborate with top financial institutions globally, allowing our clients to secure credit and build a unified financial reputation across multiple regions.
          </p>
          <p className="">
          We are more than just a credit score company—we are your partner in navigating the complexities of international finance. Our unique approach combines innovative technology, strategic partnerships, and expert financial consulting to ensure that your credit journey is as smooth and successful as possible, no matter where you go.
          </p>
        </div>
      </div>
    </section>
  )
}

export default WhoWeAre