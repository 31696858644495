import React, { useState } from 'react';
import BaseDirectories from '../../base directories/BaseDiectories';
import { Link } from 'react-router-dom';
import Button from '../../components/ui/Button';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const UserSignup = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log({ name, email, password });
  };
  // Toggle between showing and hiding password
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="max-h-screen bg-gradient-to-r from-[#2D517B] to-[#49C7EC] flex justify-center items-center gap-16 overflow-y-hidden">
      <div className="md:flex flex-col hidden">
        <div className="">
          <img
            src={`${BaseDirectories.LOGOS_DIR}/white.png`}
            alt="Arrow"
            className=""
          />
        </div>
        <div className="">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/SignupLogin/signupside.png`}
            alt="Arrow"
            className=""
          />
        </div>
        <div className="text-white gap-6 flex flex-col mb-10">
          <h1 className="text-3xl font-bold mt-6">Building the Future...</h1>
          <p className="max-w-96">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.{' '}
          </p>
        </div>
        <div className="">
          <img
            src={`${BaseDirectories.IMAGES_DIR}/SignupLogin/carousel.png`}
            alt="Arrow"
            className=""
          />
        </div>
      </div>
      <div className="bg-white rounded-2xl md:mt-60 my-4 h-screen md:w-[30rem] w-[90%]">
        <div>
          <div className="text-start mx-12 pt-8">
            <span className="font-semibold">LET'S GET YOU STARTED</span>
            <p className="text-2xl">Create an Account</p>
          </div>
          <form className="max-w-screen-lg mx-12 space-y-2 mt-8">
            <fieldset className="border-2 border-gray-300 rounded-lg p-2">
              <legend className="px-2 text-sm text-gray-700">Your Name</legend>
              <input
                type="text"
                id="text"
                value={name}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full rounded-lg focus:outline-none"
                placeholder="Enter your Name"
                required
              />
            </fieldset>
            <fieldset className="border-2 border-gray-300 rounded-lg p-2">
              <legend className="px-2 text-sm text-gray-700">Email</legend>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full rounded-lg focus:outline-none"
                placeholder="Enter your email"
                required
              />
            </fieldset>

            <fieldset className="border-2 border-gray-300 rounded-lg p-2 relative">
              <legend className="px-2 text-sm text-gray-700">Password</legend>
              <input
                type={showPassword ? 'text' : 'password'} // Dynamically change type based on state
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full rounded-lg focus:outline-none"
                placeholder="Enter your password"
                required
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute top-auto  right-4 text-sm text-BrandCerulean font-bold focus:outline-none"
              >
                {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
              </button>
            </fieldset>

            <Button
              type="submit"
              content="GET STARTED"
              route="/signup"
              className="w-full py-3 bg-BrandCerulean text-white font-bold rounded-md hover:bg-opacity-90 focus:ring-4 focus:ring-BrandCerulean focus:ring-opacity-50"
            />
          </form>
          <div className="text-center mt-2">
            <div className="flex items-center justify-center mt-2">
              <hr className="flex-grow border-t border-gray-300" />
              <span className="mx-4 text-gray-500">OR</span>
              <hr className="flex-grow border-t border-gray-300" />
            </div>
            <div className="flex flex-col gap-4 mt-4 mx-12 justify-center items-center">
              <button className="flex border text-[616161] items-center justify-center gap-10 w-full p-2 rounded-lg">
                <img
                  src={`${BaseDirectories.LOGOS_DIR}/google.png`}
                  alt="google"
                  className=""
                />
                <span>Sign up with Google</span>
              </button>
              <button className="flex border text-[616161] items-center justify-center gap-10 w-full p-2  rounded-lg">
                <img
                  src={`${BaseDirectories.LOGOS_DIR}/facebook.png`}
                  alt="google"
                  className=""
                />
                <span> Sign up with Facebook</span>
              </button>
              <button className="flex border text-[616161] items-center justify-center gap-10 w-full p-2 rounded-lg">
                <img
                  src={`${BaseDirectories.LOGOS_DIR}/apple.png`}
                  alt="google"
                  className=""
                />
                <span>Sign up with Apple </span>
              </button>
            </div>
          </div>
          <div className="text-center mt-16 flex justify-center">
            <p className="text-gray-600 mr-2">Already have an account? </p>
            <Link
              to="/login"
              className="text-BrandCerulean font-bold underline"
            >
              LOGIN HERE
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSignup;
