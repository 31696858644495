import React from 'react'
import { Helmet } from "react-helmet-async"
import MainLayout from "../../layouts/MainLayout"
import ServicesHero from "../../components/sections/ServicesHero"
import ServicesSections from "../../components/sections/ServicesSections"
import ServicesMission from "../../components/sections/ServicesMission"

const Services = () => {
  return (
    <>
    <Helmet>
      <title>UNICRED</title>
      <link rel="canonical" href={window.location.href} />
      <meta name="description" content="what is the content" />
      <meta name="keywords" content="Add keywords here" />
    </Helmet>
    <MainLayout>
     <ServicesHero/>
      <ServicesSections/>
      <ServicesMission/>
    </MainLayout>
  </>
  )
}

export default Services