import React from 'react';
import Button from '../ui/Button';
import BaseDirectories from "../../base directories/BaseDiectories";

const BridgingTheGap = () => {
  return (
    <section>
      <div className="flex flex-col justify-center items-center">
        <div
          className="bg-no-repeat bg-center h-44 flex items-center relative"
          style={{
            backgroundImage: `url('assets/images/generalbg.png') `,
          }}
        >
          <h1 className="text-BrandCerulean text-center font-bold underline text-[36px] md:text-[60px]">
            Bridging the Credit Gap
          </h1>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 mx-16 shadow-md items-center bg-BrandWhite my-8" >
          <div className=" rounded-lg p-10 py-16 h-76 max-w-[60rem]">
            <p className="text-[#828282] mb-4 max-w-96 font-[400]">
              We are on a mission to make credit accessible for immigrants and
              underserved communities across the AFRICA.
            </p>
            <Button
              content="Sign Up Now"
              route="/signup"
              className="px-8 flex py-2 rounded-full bg-BrandCyan text-BrandWhite flex-grow"
            />
          </div>
          <div className="" style={{width:'90%'}}>
          <img
              src={`${BaseDirectories.IMAGES_DIR}/HomeSection/bcg1.png`}
              alt="Arrow"
              className="h-76"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BridgingTheGap;
