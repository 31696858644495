import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-BrandCerulean h-70 text-white rounded-tr-[4rem] grid md:grid-cols-2 md:gap-6 gap-8 md:p-20 p-6">
      <div className="grid grid-cols-3 md:gap-4 gap-8">
        <div className="">
          <h4 className="font-bold">Quick Link</h4>
          <ul className="">
            <li>Home</li>
            <li>How it works</li>
            <li>Contact Us</li>
            <li>About</li>
          </ul>
        </div>
        <div className="">
          <h4 className="font-bold">Social Media</h4>
          <ul className="">
            <li>Instagram</li>
            <li>Facebook</li>
            <li>Twitter</li>
           
          </ul>
        </div>
        <div className="">
          <h4 className="font-bold">Legal Links</h4>
          <ul className="">
            <li>Term</li>
            <li>Privacy</li>
          </ul>
        </div>
        
      </div>
      <div className="">
        <p className="text-center mb-6">Copyright © 2020</p>
        <p className="">
          UniCred is a global credit facilitator, connecting people with the
          best financial services, no matter where they are in the world. Our
          mission is to simplify access to credit for international migrants,
          starting with Nigerians relocating abroad.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
