import React, { ReactNode } from 'react';
import Nav from '../components/navbar/Nav';
import Footer from '../components/footer/Footer';

const MainLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <main className="min-h-screen pt-20">
      <Nav />
      {children}
      <Footer />
    </main>
  );
};

export default MainLayout;
