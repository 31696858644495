import React from 'react'
import Button from "../ui/Button"

const ServicesMission = () => {
  return (
    <section className="flex flex-col justify-center items-center my-10 mx-4 ">
        <div className="text-center font-[600] opacity-60 text-[18px] md:text-[28px] pt-0 md:max-w-[60rem] leading-10">
          <p className="">
          At UniCred, our mission is to empower you with the financial tools and credit solutions you need to succeed, no matter where your journey takes you. Whether you're starting fresh in a new country or seeking to build your global credit profile, we’re here to support you every step of the way.
          </p>
        </div>
        <div className="my-10">
          <Button
            content="Contact Us to Learn More About Our Services"
            route="/signup"
            className="px-8 flex py-2 md:text-lg text-sm rounded-md text-white shadow-2xl bg-BrandCerulean uppercase font-bold"
          />
        </div>
    </section>
  )
}

export default ServicesMission