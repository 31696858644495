import React from 'react';
import MainLayout from '../../layouts/MainLayout';
import HomeHero from '../../components/sections/HomeHero';
import { Helmet } from 'react-helmet-async';
import WhyUnicred from '../../components/sections/WhyUnicred';
import BridgingTheGap from '../../components/sections/BridgingTheGap';
import TailoredSolution from '../../components/sections/TailoredSolution';
import CreditJourney from '../../components/sections/CreditJourney';
import HowItWorks from '../../components/sections/HowItWorks';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>UNICRED</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="what is the content" />
        <meta name="keywords" content="Add keywords here" />
      </Helmet>
      <MainLayout>
        <HomeHero />
        <WhyUnicred />
        <BridgingTheGap />
        <TailoredSolution />
        <CreditJourney />
        <HowItWorks />
      </MainLayout>
    </>
  );
};

export default Home;
