

const TailoredSolution = () => {
  return (
    <section className="m-4">
      <div
        className="top-0 bg-cover bg-center w-full min-h-screen"
        style={{
          backgroundImage: `url('assets/images/HomeSection/tsn.png') `,
        }}
      >
        <div className="flex justify-end text-end h-full pt-[50em] p-20">
          <h2 className="text-4xl font-rubik font-extrabold max-w-96 text-center mt-30 text-BrandCerulean underline">
            Tailored Solutions for Nigerians
          </h2>
        </div>
      </div>
      <div className="flex flex-col mt-16 font-rubik">
        <div
          className="bg-no-repeat bg-center h-44"
          style={{
            backgroundImage: `url('assets/images/generalbg.png') `,
          }}
        >
          <div className="flex flex-col justify-center items-center text-center">
            <h2 className="font-medium text-4xl my-4">
              Specifically Designed for Nigerians Moving Abroad
            </h2>
            <p className="md:max-w-xl text-xl mb-6 text-center mt-6">
              At UniCred, we understand the unique financial challenges faced by
              Nigerians relocating abroad, especially to Canada. We offer
              tailor-made solutions:
            </p>
          </div>
        </div>
        <div className="flex md:flex-row flex-col gap-4 justify-center  my-8 text-[#828282]">
          <div className="bg-white shadow-xl rounded-lg p-10 max-w-[367px]">
            <h2 className="text-BrandCerulean font-bold">Credit Without Borders:</h2>
            <p className="mt-4">
              No need for Canadian or international credit history to get
              started.
            </p>
          </div>
          <div className="bg-white shadow-xl rounded-lg p-10 max-w-[367px]">
            <h2 className="text-BrandCerulean font-bold">Local Guidance, Global Reach:</h2>
            <p className="mt-4">
              Our expert team in Nigeria works with you every step of the way.
            </p>
          </div>
          <div className="bg-white shadow-xl rounded-lg p-10 max-w-[367px]">
            <h2 className="text-BrandCerulean font-bold">Support in Multiple Countries:</h2>
            <p className="mt-4">
              Credit solutions for not just Canada, but also the US, UK, UAE,
              and more.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TailoredSolution;
