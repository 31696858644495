import React from 'react'
import { Helmet } from "react-helmet-async"
import MainLayout from "../../layouts/MainLayout"

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>UNICRED</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="what is the content" />
        <meta name="keywords" content="Add keywords here" />
      </Helmet>
      <MainLayout>
       
        <section className="md:mx-48 mx-2">
         
        </section>
      </MainLayout>
    </>
  )
}

export default Contact