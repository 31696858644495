import React from 'react';

const ServicesHero = () => {
  return (
    <section className="flex flex-col justify-center items-center">
      <div
        className="min-h-screen top-0 w-full bg-cover bg-no-repeat bg-center mt-10"
        style={{
          backgroundImage: `url('assets/images/ServicesSection/herobg.png') `,
        }}
      >
        <div className="w-1/2 font-rubik my-10 md:tracking-[-6px] flex flex-col pt-[40rem] md:px-20 px-6">
          <h2 className="md:text-[70px] text-[40px] font-[800] text-[#313131]">
            UniCred Services:
          </h2>
          <span className="md:text-5xl text-2xl md:leading-10 leading-none font-semibold max-w-[483px]">Empowering Your Credit Journey Globally</span>
        </div>
      </div>
      <div className="text-center font-[600] opacity-60 text-[18px] md:text-[28px] pt-0 md:max-w-[60rem] leading-10 my-10 mx-6 text-[#161616]">
        <p className="">
          At UniCred, we understand that managing credit in a new country can be
          challenging. That’s why we offer comprehensive credit solutions that
          help you build, manage, and utilize your credit score, no matter where
          you are. Whether you're relocating to Canada, the US, or anywhere
          else, our services ensure you have the financial foundation to
          succeed.
        </p>

      </div>
    </section>
  );
};

export default ServicesHero;
