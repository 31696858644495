import { ReactNode } from "react";

const SectionLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <main className="md:mx-16 mx-auto px-10 lg:px-0">
      {children}
    </main>
  );
};

export default SectionLayout;
