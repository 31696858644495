import { Link } from 'react-router-dom';
import Button from '../ui/Button';

type MobileNavProps = {
  isOpen: boolean;
  toggleMenu: () => void;
};

const MobileNav: React.FC<MobileNavProps> = ({ isOpen, toggleMenu }) => {
  const menuItems = [
    { label: 'Home', route: '/' },
    { label: 'About', route: '/about' },
    { label: 'Services', route: '/services' },
    { label: 'Contact', route: '/contact' },
  ];
  return (
    <>
      {isOpen && (
        <div className="md:hidden">
          <ul className="fixed left-0 top-30 flex flex-col items-end gap-6 p-4 bg-[#5EC6E4] w-[300px] h-[100%] shadow-lg z-50">
            {menuItems.map((item)=>(
                <li key={item.route} onClick={toggleMenu}>
                    <Link to={item.route} className="hover:text-BrandCerulean">{item.label}</Link>
                </li>
            ))}
            <li onClick={toggleMenu}>
              <Button
                content="Login"
                route="/login"
                className="px-6 text-lg rounded-md"
              />
            </li>
            <li onClick={toggleMenu}>
              <Button
                content="SignUp"
                route="/signup"
                className="px-8 py-4 text-lg rounded-md bg-white shadow-lg text-BrandCerulean"
              />
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default MobileNav;
