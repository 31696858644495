import React from 'react';


const WhyUnicred = () => {
  return (
     <section className="bg-BrandCyanPurge font-rubik md:px-20 p-6 flex flex-col justify-center items-center text-center rounded-bl-[3rem]">
       <div className="md:px-40 p-2">
         <div>
           <h1 className="text-4xl font-bold my-8 leading-10">
             <span className="text-BrandCerulean underline">Why</span> <span className="text-BrandWhite">Unicred?</span>
           </h1>
         </div>
         <div className="">
           <h2 className="font-medium text-4xl my-4">Global Credit Solutions for Smooth Transitions</h2>
           <p className="md:max-w-3xl text-xl mb-6">
             UniCred bridges the gap for those seeking financial services across
             borders. Whether you’re relocating from Nigeria to Canada or any
             other part of the world, our unified system provides seamless access
             to credit and financial support.
           </p>
         </div>
       </div>
       <div className="grid md:grid-cols-3 grid-cols-1 gap-8 mx-4 my-8 text-[#828282]">
         <div className="bg-white shadow-xl rounded-lg p-10 text-center max-w-[367px]">
           Access Local Credit in New Countries Secure loans, credit cards, or
           mortgages even before you land.
         </div>
         <div className="bg-white shadow-xl rounded-lg p-10 text-center max-w-[367px]">
           Get Pre-approved Before Relocating Plan ahead and manage finances with
           pre-approved credit before you move.
         </div>
         <div className="bg-white shadow-xl rounded-lg p-10 text-center max-w-[367px]">
           Get Pre-approved Before Relocating Plan ahead and manage finances with
           pre-approved credit before you move.
         </div>
       </div>
     </section>
  );
};

export default WhyUnicred;