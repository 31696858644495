import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UserLogin from '../pages/auth/UserLogin';
import UserSignup from '../pages/auth/UserSignup';
import NotFound from '../pages/NotFound/NotFound';
import Home from "../pages/landingpage/Home";
import About from "../pages/landingpage/About";
import Services from "../pages/landingpage/Services";
import Contact from "../pages/landingpage/Contact";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* Login and signup routes */}
        <Route>
          <Route path="/login" element={<UserLogin />}></Route>
          <Route path="/signup" element={<UserSignup />}></Route>
        </Route>

        {/* 404 page */}
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
