import React from 'react';


const AboutHero = () => {
  return (
    <section>
      <div
        className="h-screen top-0 w-full bg-cover bg-center"
        style={{
          backgroundImage: `url('assets/images/AboutSection/herobg.png') `,
        }}
      >
        <div className="w-1/2 flex justify-center pt-36">
            <h2 className="text-4xl font-rubik font-[800] text-[#313131]">
              About UniCred
            </h2>
        </div>
      </div>
    </section>
  );
};

export default AboutHero;
