import React from 'react';
import BaseDirectories from '../../base directories/BaseDiectories';
import { Link } from 'react-router-dom';

const HomeHero = () => {
  return (
    <section className="mt-12">
      <div
        className="md:flex md:flex-row flex-col bg-no-repeat bg-left-top bg-fixed pt-10"
        style={{
          backgroundImage: `url('assets/images/generalbg.png') `,
        }}
      >
        <div className="flex flex-col md:w-[40%] gap-6">
          <div className="md:ml-36 md:mt-20 mt-2 mx-5">
            <div>
              <h2 className="text-BrandCerulean font-inter font-extrabold text-5xl leading-snug">
                Seamless Global Credit Solution For Your International Journey
              </h2>
            </div>
            <div className="my-8 md:w-[70%]">
              <p className="font-arima font-bold text-[#828282]">
                Empowering Nigerians to Access Credit While Relocating to Canada
                and Beyond
              </p>
            </div>
            <div className="skewed-btn w-[160px] md:w-[260px] p-[10px] md:p-[8px] text-white text-[20px] md:text-[16px] bg-[#7DCCC0] relative rounded-md font-bold text-center mb-2">
              <Link to="/signup" className="text-white">
                Sign Up Now
              </Link>
            </div>
          </div>
          <div className="h-auto self-start">
            <img
              src={`${BaseDirectories.IMAGES_DIR}/HomeSection/heroarrow.png`}
              alt="Arrow"
              className=""
            />
          </div>
        </div>
        <div
          className=" md:w-[60%] bg-contain bg-no-repeat flex items-end justify-center"
          style={{
            backgroundImage: `url('assets/images/HomeSection/herobg.png') `,
            backgroundPosition: 'right 100px',
          }}
        >
          <img
            className=""
            src={`${BaseDirectories.IMAGES_DIR}/HomeSection/hero.png`}
            alt="hero"
          />
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
