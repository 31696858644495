import React from 'react';
import SectionLayout from '../../layouts/SectionLayout';
import BaseDirectories from '../../base directories/BaseDiectories';

const ServicesSections = () => {
  return (
    <SectionLayout>
      {/* first  */}
      <div className="flex flex-col gap-12">
        <div className="bg-BrandPale flex flex-col md:flex-row font-rubik items-center">
          <div className="p-6 md:p-10 md:w-1/2">
            <h1 className="font-bold text-3xl md:text-4xl my-4 md:my-6">
              Global Credit Score Building
            </h1>
            <p className="text-base text-[#161616] leading-relaxed md:text-lg">
              Start Building Your Credit History Before You Even Relocate.
              Relocating to a new country often means starting from scratch with
              your credit history. At UniCred, we partner with financial
              institutions around the world to allow you to build your credit
              score before you even set foot in your new country.
            </p>
          </div>

          <div className="p-6 md:p-10 md:w-1/2 flex justify-center items-center">
            <img
              src={`${BaseDirectories.IMAGES_DIR}/ServicesSection/gcsb.png`}
              alt="Credit Score Building"
              className="w-full h-auto object-cover"
            />
          </div>
        </div>
        <div className="bg-BrandPale flex flex-col md:flex-row font-rubik items-center">
          <div className="p-6 md:p-10 md:w-1/2 flex justify-center items-center">
            <img
              src={`${BaseDirectories.IMAGES_DIR}/ServicesSection/pagc.png`}
              alt="Credit Score Building"
              className="w-full h-auto object-cover"
            />
          </div>
          <div className="p-6 md:p-10 md:w-1/2">
            <h1 className="font-bold text-3xl md:text-4xl my-4 md:my-6">
            Pre-Approved Global Credit
            </h1>
            <p className="text-base leading-relaxed md:text-lg text-[#161616]">
            Get Credit Approval Even Before You MoveMoving to a new country comes with financial challenges. With UniCred, you can get pre-approved for loans, mortgages, or credit cards before you leave your home country. We work with top-tier lenders in countries like Canada and the US to ensure your approval process is quick and smooth.
            </p>
          </div>
        </div>
        <div className="bg-BrandPale flex flex-col md:flex-row font-rubik items-center">
          <div className="p-6 md:p-10 md:w-1/2">
            <h1 className="font-bold text-3xl md:text-4xl my-4 md:my-6">
            International Credit Monitoring
            </h1>
            <p className="text-base leading-relaxed md:text-lg text-[#161616]">
            Stay on Top of Your Credit, No Matter Where You AreOur international credit monitoring service lets you track and manage your credit score across multiple countries. Whether you’re in Nigeria, Canada, or anywhere else, you can access real-time updates on your credit profile.
            </p>
          </div>

          <div className="p-6 md:p-10 md:w-1/2 flex justify-center items-center">
            <img
              src={`${BaseDirectories.IMAGES_DIR}/ServicesSection/icm.png`}
              alt="Credit Score Building"
              className="w-full h-auto object-cover"
            />
          </div>
        </div>
        <div className="bg-BrandPale flex flex-col md:flex-row font-rubik items-center">
          <div className="p-6 md:p-10 md:w-1/2 flex justify-center items-center">
            <img
              src={`${BaseDirectories.IMAGES_DIR}/ServicesSection/fcir.png`}
              alt="Credit Score Building"
              className="w-full h-auto object-cover"
            />
          </div>
          <div className="p-6 md:p-10 md:w-1/2">
            <h1 className="font-bold text-3xl md:text-4xl my-4 md:my-6">
            Financial Consulting for International Relocation
            </h1>
            <p className="text-base leading-relaxed md:text-lg text-[#161616]">
            Expert Financial Advice for Your Global Move Relocating abroad involves more than just packing bags – it requires a sound financial strategy. At UniCred, we offer specialized consulting services to help you manage your finances, build credit, and make informed financial decisions in your new country.
            </p>
          </div>
        </div>
      </div>
    </SectionLayout>
  );
};

export default ServicesSections;
