/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  type?: 'button' | 'submit' | 'reset';
  content: string;
  className?: string;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  icon?: string;
  title?: string;
  url?: string;
  route?: string;
  style?: React.CSSProperties;
  
};

const Button: FC<Props> = ({
  type = 'button',
  content,
  onClick,
  loading,
  disabled,
  icon,
  title,
  url,
  route,
  style,
  ...otherProps
}) => {
  function createRipple(event: any) {
    const button = event.currentTarget;
    const circle = document.createElement('span');
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
    circle.classList.add('ripple');

    const ripple = button.getElementsByClassName('ripple')[0];
    if (ripple) ripple.remove();

    button.appendChild(circle);
  }

  return (
    <button
      style={style}
      type={type}
      title={title || ''}
      className={`relative overflow-hidden py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 disabled:opacity-50 ${disabled ? 'cursor-not-allowed' : ''}`}
      onClick={onClick ? (e) => { onClick(); createRipple(e); } : createRipple}
      disabled={loading || disabled}
      {...otherProps}
    >
      <span className="relative z-10 flex items-center justify-center">
        {icon && <span className={`${icon} mr-2`}></span>}
        {route ? <Link to={route}>{content}</Link> : null}
        {url ? <a href={url}>{content}</a> : null}
        {!route && !url && content}
        {loading && (
          <span className="ml-2 fas fa-spinner fa-pulse"></span>
        )}
      </span>
      {/* Ripple effect */}
      <span className="absolute bg-white bg-opacity-50 rounded-full transform scale-0 transition-transform duration-500 ease-out"></span>
    </button>
  );
};

export default Button;
