import React from 'react';

const HowItWorks = () => {
  return (
    <section className="font-rubik">
      <div
        className="bg-no-repeat bg-center h-44"
        style={{
          backgroundImage: `url('assets/images/generalbg.png') `,
        }}
      >
        <div className="flex flex-col text-center">
          <span className="mt-8 font-bold text-5xl text-BrandCerulean underline">
            How It Works
          </span>
          <span className="text-5xl mt-2">A Simple, Hassle-free Process</span>
        </div>
      </div>
      <div className="flex md:flex-row flex-col gap-4 justify-center  my-8 text-[#828282]">
        <div className="bg-white shadow-xl rounded-lg p-10 max-w-[367px]">
          <span className="text-3xl text-[#000]">Step 1</span>
          <h2 className="font-bold mt-4">Register for Free</h2>
          <p className="">
            Sign up online and create your UniCred profile in just minutes.
          </p>
        </div>
        <div className="bg-white shadow-xl rounded-lg p-10 max-w-[367px]">
          <span className="text-3xl text-[#000]">Step 2</span>
          <h2 className="font-bold mt-4">Apply for Global Credit</h2>
          <p className="">
            Submit your credit application for loans, credit cards, or financial
            products in the country you’re relocating to.
          </p>
        </div>
        <div className="bg-white shadow-xl rounded-lg p-10 max-w-[367px]">
          <span className="text-3xl text-[#000]">Step 3</span>
          <h2 className="font-bold mt-4">Get Approval & Build Your Credit</h2>
          <p className="">
            Get instant approval and start using credit. Build your credit
            history, globally and seamlessly.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
