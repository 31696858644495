import React, { useState } from 'react';
import BaseDirectories from '../../base directories/BaseDiectories';
import { Link } from 'react-router-dom';
import Button from '../ui/Button';
import MobileNav from './MobileNav';

export default function Nav() {
  //Controls Mobile Menu view
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="bg-white shadow-lg fixed w-full z-50 top-0">
      <div className="container flex items-center justify-between py-8 font-rubik font-thin text-lg leading-5">
        <div className="">
          <Link to="/">
            <img
              src={`${BaseDirectories.LOGOS_DIR}/colored.png`}
              alt="unicred logo"
              className="md:w-[60%] w-[80%]"
            />
          </Link>
        </div>

        {/* Desktop  */}
        <div className=" hidden md:block">
          <ul className="flex items-center gap-10 text-[#313131]">
            <li className="hover:border-b-4 hover:border-BrandCerulean">
              <Link to="/">Home</Link>
            </li>
            <li className="hover:border-b-4 hover:border-BrandCerulean">
              <Link to="/about">About</Link>
            </li>
            <li className="hover:border-b-4 hover:border-BrandCerulean">
              <Link to="/services">Services</Link>
            </li>
            <li className="hover:border-b-4 hover:border-BrandCerulean">
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
        <div className="">
          <ul className="md:flex hidden items-center gap-6 font-semibold">
            <li>
              <Button
                content="Login"
                route="/login"
                className="px-6 text-lg rounded-md"
              />
            </li>
            <li>
              <Button
                content="SignUp"
                route="/signup"
                className="px-8 flex py-4 text-lg rounded-md bg-white shadow-lg text-BrandCerulean flex-grow"
              />
            </li>
          </ul>
        </div>
        {/* Mobile hamburger */}
        <div className="md:hidden">
        <button
            className={`block hamburger lg:hidden ${isMobileMenuOpen ? 'open' : ''}`}
            onClick={toggleMobileMenu}
          >
            <span className="hamburger-top"></span>
            <span className="hamburger-middle"></span>
            <span className="hamburger-bottom"></span>
          </button>
        </div>
      </div>
      {/* Mobile  */}
      <MobileNav isOpen={isMobileMenuOpen} toggleMenu={toggleMobileMenu} />
    </nav>
  );
}
